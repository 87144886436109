import React from 'react';
import moment from 'moment';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Colors } from '../../../themes/colors';
import { getJobStatus } from './utils';
import { FETCH_JOBS_WITH_TRANSACTIONS, FETCH_RECEIPT_LIST } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';

import sortArrows from '../../../images/sortArrows.svg';
import arrowDown from '../../../images/arrowDown.svg';
import arrowUp from '../../../images/arrowUp.svg';
import arrowRight from '../../../images/arrow_right.svg';

const ShiftReceiptTable = React.forwardRef(({ sort, onSort, onScroll }, ref) => {
  const history = useHistory();
  const jobsWithTransaction = useSelector((state) => state.job.jobsWithTransaction);

  const loadingSelector = createLoadingSelector([FETCH_RECEIPT_LIST, FETCH_JOBS_WITH_TRANSACTIONS]);
  const isLoading = useSelector((state) => loadingSelector(state));

  const renderSortArrowButton = (sortName) => {
    if (sortName === sort.field) {
      if (sort.order === 'desc') {
        return <img src={arrowDown} alt="arrow_down" style={Styles.arrowStyle} />;
      }
      return <img src={arrowUp} alt="arrow_up" style={Styles.arrowStyle} />;
    }
    return <img src={sortArrows} alt="sort_arrow" style={Styles.arrowStyle} />;
  };

  return (
    <div
      ref={ref}
      onScroll={onScroll}
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        overflowY: 'auto',
        overflowX: 'auto',
      }}
    >
      <div
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: Colors.white,
          }}
        >
          <div
            style={{
              width: '100%',
              margin: 10,
              display: 'flex',
            }}
          >
            <span style={Styles.titleTextShift}>Office</span>
            <span style={Styles.titleTextShift}>Professional</span>
            <span style={Styles.titleTextShift} onClick={() => onSort('jobDate')}>
              Shift Date
              {renderSortArrowButton('jobDate')}
            </span>
            <span style={Styles.titleTextShift}>Shift Status</span>
            <span style={Styles.titleTextShift}># of Transactions</span>
            <span style={Styles.titleTextShift}>View Transactions</span>
          </div>

          <div style={{ height: 1, backgroundColor: Colors.neutral_50 }} />
        </div>

        {jobsWithTransaction?.map((job, index) => (
          <motion.div
            key={job.id}
            initial={{ opacity: 0, x: -10 * (index % 8) }}
            animate={{ opacity: 1, x: 0 }}
            whileHover={{
              boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
              transition: { duration: 0.3 },
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              flex: 1,
              margin: 10,
              display: 'flex',
              backgroundColor: Colors.neutral_50,
              padding: '8px 0px',
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50px',
            }}
            onClick={() => history.push(`/dashboard/receipts/transactions/${job.id}`)}
          >
            <span style={Styles.normalText}>{job.offer_owner?.office_name}</span>

            <span style={Styles.normalText}>{job.offer_final_reciever || '-'}</span>

            <span style={Styles.normalText}>
              {moment(job?.shift_date).format('ddd, MMM DD, YYYY')}
            </span>

            <span
              style={{
                fontFamily: 'Nunito',
                fontSize: 14,
                color: getJobStatus(job.shift_status)?.color,
                textTransform: 'uppercase',
                width: '20%',
                margin: 10,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {getJobStatus(job.shift_status)?.label || job.shift_status}
            </span>

            <span style={Styles.normalText}>{job.no_transactions}</span>

            <span
              style={{
                ...Styles.normalText,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src={arrowRight}
                style={{
                  width: 10,
                  objectFit: 'cover',
                  marginRight: 20,
                }}
                alt="arrow_right"
              />
            </span>
          </motion.div>
        ))}

        {isLoading && (
          <div
            style={{
              width: '100%',
              height: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader size={50} color={Colors.primary_500} loading />
          </div>
        )}
      </div>
    </div>
  );
});

const Styles = {
  titleTextShift: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '18px',
    color: Colors.primary_500,
    width: 'calc(100% / 6)',
    textAlign: 'center',
  },
  titleText: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 16,
    color: Colors.primary_500,
    textTransform: 'capitalize',
    width: '20%',
    textAlign: 'center',
  },
  titleTextButton: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 16,
    color: Colors.primary_500,
    textTransform: 'capitalize',
    width: '20%',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  normalText: {
    fontFamily: 'Nunito',
    fontSize: 15,
    color: Colors.neutral_600,
    textTransform: 'capitalize',
    width: '20%',
    margin: '0px 20px',
    textAlign: 'center',
  },
  arrowStyle: {
    width: 10,
    marginLeft: 8,
  },
};

export default ShiftReceiptTable;

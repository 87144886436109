import { ColorKeys, Colors } from '~/themes/colors';
import { iconRegistry } from './utils';

type TIconNames = keyof typeof iconRegistry;

interface IIconProps {
  name: TIconNames;
  color?: ColorKeys | string;
  size?: number;
}

export const Icon = ({ name, color, size }: IIconProps) => {
  const IconComponent = iconRegistry[name];

  let resolvedColor: string | undefined;
  if (color) {
    resolvedColor =
      typeof color === 'string' && color in Colors ? Colors[color as ColorKeys] : color;
  }

  return <IconComponent color={resolvedColor} size={size} />;
};
